.circular_image_container {
    position: relative;
    width: 20vw; 
    height: 20vw; 
  }

.circular_image {
    width: 20vw; 
    height: 20vw; 
    border-radius: 50%; 
    object-fit: cover; 
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    background-color: rgba(161, 125, 17, 0.5);
  }