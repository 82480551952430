.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6;
  }
  
  .modal_content {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 5;
    /* width: 75vw; */
  }

  .close_button {
    color: #6e2424a3;
    float: right;
    font-size: 28px;
    font-weight: bold;
    background: none;
    border: none;
}

.close_button:hover,
.close_button:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}