:root {
  --primary-color: #16181D;
  --secondary-color: rgba(214, 163, 11, 0.816);
  --tiertiary-color: rgba(162, 123, 5, 0.936);
  --light-gray: #ECF0F1;
  --dark-gray: #2C3E50;
  --error-red: #E74C3C;
  --success-green: #2ECC71;
  --transition-speed: 0.3s;
}

.home_container {
  /* width: 100vw; */
  margin: 0;

}

.hero_container {
  position: relative;
  /* min-height: 100vh; */
  font-family: 'Saira Semi Condensed', sans-serif;

}

.hero_img {
  display: flex;
  justify-content: center;
  position: relative;
  height: 60vh;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(161, 125, 17, 0.3);
}
.line{
  /* width: 50%; */
}
.txt_overlay_container {
  position: absolute;
  top: 65%;
  left: 55%;
  transform: translate(-50%, -50%);
  text-align: right;
  color: white;
  width: 85%;
  padding-right: 5vw;
}

.about_us_container {
  position: relative;
  /* width: 100%; */
  font-family: 'Saira Semi Condensed', sans-serif;
}

.about_us_img {
  display: flex;
  justify-content: center;
  position: relative;
  height: 40vh;
  overflow: hidden;
}

.about_us_txt_overlay_container {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /* color: rgb(30, 30, 30); */
  color: white;
  padding: .5em;
  width: 85%;
  height: 25vh;
  width: 50%;
  /* background-color: rgba(250, 228, 163, 0.3); */
  background-color: rgba(135, 123, 89, 0.6);
}

.about_us_txt_overlay_container>p {
  font-size: 1vw;
  font-weight: 600;
  width: 80%;
  height: fit-content;
}

.about_us_txt_overlay_container>span {
  font-size: 2.5vw;
  font-weight: 900;
}

.hero_txt_main {
  margin: 0;
  font-size: 3.2vw;
}

.hero_txt_secondary {
  margin: 0;
  font-size: 1vw;
}

.hero_btn {
  margin: 0;
  font-size: 1vw;
  border: none;
  border-radius: 10px;
  padding: .6vw;
  font-weight: bold;
  color: white;
  align-items: end;
  background-color: var(--secondary-color);
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  /* Transition for color, shadow, and transform */
  position: relative;
  overflow: hidden;
}

.hero_btn:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, --tiertiary-color);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}


.hero_btn:hover {
  background-color: var(--secondary-color);
  box-shadow: 0 4px 8px rgba(162, 123, 5, 0.9);
  transform: scale(1.05);
}

.hero_btn:hover::before {
  opacity: 1;
}


.inventory_search_container {
  background-color: var(--primary-color);
  /* height: .5vw; */
  ;
}


.contact_section {
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* padding: 2vh 0; */
  padding: 5vw 0;
  width: 100vw;
}

.line_box{
  padding: 10% .2vw;
  height: 35vh;
  border: 1px solid #ffffff;
}

.image_container {
  display: flex;
  height: 75%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: white;
}

.image_container>h1 {
  font-size: 2vw;
}

.image_container>p {
  margin-top: -.5vw;
  text-wrap: wrap;
  text-align: center;
  font-size: 1.2vw;
  font-weight: bold;
  width: 45%;
}


.contact_btn {
  margin-top: 2vh;
  font-size: 1.5vw;
  border: none;
  border-radius: 10px;
  padding: .9vw;
  font-weight: bold;
  color: white;
  align-items: end;
  background-color: var(--secondary-color);
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  /* Transition for color, shadow, and transform */
  position: relative;
  overflow: hidden;
}

.contact_btn:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, --tiertiary-color);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.contact_btn:hover {
  background-color: var(--secondary-color);
  box-shadow: 0 4px 8px rgba(162, 123, 5, 0.9);
  transform: scale(1.05);
}

.contact_btn:hover::before {
  opacity: 1;
}

#reviews-container a {
  display: none !important;
}