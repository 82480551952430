:root {
    --primary-color: #16181D;
    --secondary-color: rgba(214, 163, 11, 0.816);
    --tiertiary-color: rgba(162, 123, 5, 0.936);
    --thecolor: rgb(179, 136, 9);
    --light-gray: #ECF0F1;
    --dark-gray: #2C3E50;
    --error-red: #E74C3C;
    --success-green: #2ECC71;
    --transition-speed: 0.3s;
  }


.wrapper {
    font-family: Arial, sans-serif;
    min-height: 100vh;
    margin: 0 auto;
    background-color: var(--primary-color);
}

.nav_img {
    display: flex;
    justify-content: center;
    position: relative;
    /* height: 20vh; */
    overflow: hidden;
  }
  
  .nav_img_container {
    position: relative;
    font-family: 'Saira Semi Condensed', sans-serif;
    height: 10vh;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(161, 125, 17, 0.247);
  }
  
  .txt_overlay_container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 95%;
    padding-bottom: 10vh;
    padding-left: 1vw;
    color: white;
  }
  
  .title {
    margin: 0;
    position: absolute;
    font-size: 3vw;
    /* left: 50%; */
    /* transform: translateX(-50%); */
  }
  .line{
width: 98vw;
  }

.inventory_body_wrapper{
    display: flex;
    position: relative;
    background-color: var(--primary-color);
}

.card_container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}


/* Add Inventory Form */

.alert_message{
    font-size: x-small;
    color: red;
}
.new_car_container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}

.new_car_title{
    color: #333;
}

.add_inventory_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputs{
    padding: 14px 18px;
    margin-bottom: 18px;
    border: 1px solid #c0c0c0;
    border-radius: 6px;
    font-size: 17px;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s, box-shadow 0.3s;
    margin: 5px 5px;
}

textarea{
    max-height: 75px;
    width: 73%;
    padding: 5px;
    border: 1px solid #c0c0c0;
    border-radius: 6px;
    color: grey;
}

section{
    display: flex;
    justify-content: center;
    width: 75%;
}
input[type="radio"] {
    accent-color: #F39C12;
    cursor: pointer;
    transform: scale(1.8);
    margin: 10px 10px;
  }
  input:focus,
  select:focus{
    border-color: #F39C12;
    outline: none;
  }
textarea:focus{
    border-color: #F39C12;
    outline: none;
}
 label{
    color: grey;
 } 

table{
    background-color: #ECF0F1;
}

th{
    background-color: #F39C12;
    color: white;
    font-size: large;
    font-weight:550;
    /* border: 1px solid #f8f7f7; */
    padding: 10px;
}

td{
    border: 1px solid #dddddd;
    text-align: left;
    background-color: white;
    padding: 5px;
    text-align: center;
    color: grey;
}

.save_btn{
    justify-content: center;
     align-items: center;
     padding: 10px 10px;
     transition: all 0.3s;
     border: #d6a30bd0;
     background-color: #d6a30bd0;
     color: rgb(255, 255, 255);
     border-radius: 4px;
     cursor: pointer;
     font-size: medium;
     font-weight: bold;
     margin-left: 35px;
     margin-top: 10px;
}

