:root {
    --primary-color: #16181D;
    --secondary-color: rgba(214, 163, 11, 0.816);
    --tiertiary-color: rgba(162, 123, 5, 0.936);
    --light-gray: #ECF0F1;
    --dark-gray: #2C3E50;
    --error-red: #E74C3C;
    --success-green: #2ECC71;
    --transition-speed: 0.3s;
}
.wrapper{
    background-color: var(--primary-color);
}

.inner_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background-color: var(--primary-color);
    height: fit-content;
}
.google_map_container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47%;
    height: 50vh;
    background-color: var(--primary-color);
    position: relative;
}

.iframe_container{
    z-index: 10;
    height: 100%;
    width:  100%;
}
.google_map{
    display: flex;
    height: 100%;
    width:  100%;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    opacity: .5;
    z-index: 10;
}
.overlay{
    position: absolute;
    left: 0;
    height: 100%;
    /* bottom: 55px; */
    width: 100%;
    background-color: rgba(161, 125, 17);
    cursor: pointer;
  }
.line_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--primary-color);
    margin: 0;
    padding-top: 1vh;
}
.line_container>hr{
    width: 98%;
    margin: 0;
}
.details_container {
    display: flex;
    flex-direction: column;
    width: 38%;
    background-color: var(--primary-color);
    margin-left: 5vw;
    padding: 2vw;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: var(--light-gray);
    font-size: .8vw;
    font-weight: bold;
}


.title {
    font-size: 2.5vw;
    font-weight: bold;
    margin-bottom: 1vh;

}

.email {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .8vw;
    margin-bottom: .8vw;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: white;
    font-size: .8vw;
    font-weight: bold;
}
.email:hover {
    color: var(--secondary-color);
}
.phone_container{
    display: flex;
    align-items: center;
    gap: 1vw;
    cursor: pointer;
    color: white;
    text-decoration: none;
    margin-bottom: .8vw;
}
.phone {
    width: fit-content;

    cursor: pointer;
    margin-left: .5vw;
}
.phone_container:hover {
    color: var(--secondary-color);
}

.whatsapp_container{
    display: flex;
    align-items: center;
    gap: 1vw;
    cursor: pointer;
    margin-bottom: .8vw;
}

.whatsapp{
    cursor: pointer;
    gap: 1vw;
}
.whatsapp_container:hover {
    color: var(--secondary-color);
}

.address_container{
    display: flex;
    align-items: center;
    gap: 1vw;
    cursor: pointer;
}

.address {
    cursor: pointer;
}
.address_container:hover {
    color: var(--secondary-color);
}

.map_button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 105%;
    font-weight: bold;
    font-size: .9vw;
}

.store_hours_container {
    display: flex;
    margin-top: -.6vw;
}

.store_hours_title {
    font-size: 1.2vw;
    font-weight: bold;
}

.days {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 1%;
}

.hours {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 3%;
}