:root {
  --primary-color: #16181D;
  --secondary-color: rgba(214, 163, 11, 0.816);
  --tiertiary-color: rgba(162, 123, 5, 0.936);
  --thecolor: rgb(179, 136, 9);
  --light-gray: #ECF0F1;
  --dark-gray: #2C3E50;
  --error-red: #E74C3C;
  --success-green: #2ECC71;
  --transition-speed: 0.3s;
}

.search {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vh;
  }
  
  .titleSearch {
    font-size: 5vh;
    margin-bottom: .5vh;
    font-weight: bold;
    color: #333;
  }
  
  .search input[type="text"] {
    padding: 1vh;
    border: 1px solid #ddd;
    border-radius: .3vh;
    margin: 1vh;
    width: 12vw;
    font-size: 1.75vh;
    outline: none;
    transition: border 0.3s;
  }
  
  .search input[type="text"]:focus {
    border-color: #d6a30bd0;
  }
  
  /* .search_icon {
    margin-top: 1vw;
    cursor: pointer;
    color: #333;
    font-size: 24px;
    transition: color 0.3s;
  } */
  
  /* .search_icon:hover {
    color: #d6a30bd0;
  } */
  
  .searchTitleResponse {
    font-size: 18px;
    font-weight: bold;
    color: #444;
    margin-top: 20px;
    text-align: center;
  }
  
  .carImageCard {
    width: 100%;
    max-width: 300px;
    border-radius: 5px;
    margin: 10px 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  .input-container{
    display: flex;
    /* width: 70%; */
    align-items: center;
    justify-content: space-around;
  }
  .filter_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #d6a30bd0;
    border-radius: 5vw;
    width: 2.5vw;
    height: 5vh;
    cursor: pointer;
  }

  .year-container>select{
    border: 1px solid #ddd;
    outline: none;
    transition: border 0.3s;
    background-color: white;
    color: grey;
    cursor: pointer;

    padding: 1vh;
    border: 1px solid #ddd;
    border-radius: .3vh;
    margin: 1vh;
    width: 12vw;
    font-size: 1.75vh;
}

.radio-sold-btns-container {
}
/* .w_so_top_left_container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
} */

.radio-sold-btns-container label{
justify-content: center;
align-items: center;
padding: 1.4vh 1vw;
border: #dcdbdb;
transition: all 0.3s;
background-color: #c3c1c19f;;
color: #333;
border-radius: .5vh;
width: 1vw;
cursor: pointer;
font-size: 1vw;
font-weight: bold;
margin-right: 1vw;
}
.radio-sold-btns-container label:hover{
  border: #d6a30bd0;
  background-color: #d6a30bd0;
  color: rgb(255, 255, 255);
}

.radio-sold-btns-container input[type="radio"]{
  display: none;
  cursor: pointer;
}

.radio-sold-btns-container input[type="radio"]:checked + label {
  border: #d6a30bd0;
  background-color: #d6a30bd0 !important;;
  color: white;
  cursor: pointer;
}
.radio-sold-btns-container input[type="radio"]:checked + label:hover{
  border: #d6a30bd0;
  background-color: #d6a30bd0 !important;;
  color: white;
  cursor: pointer;
}
  
  
  