:root {
    --primary-color: #16181D;
    --secondary-color: rgba(214, 163, 11, 0.816);
    --tiertiary-color: rgba(162, 123, 5, 0.936);
    --thecolor: rgb(179, 136, 9);
    --light-gray: #ECF0F1;
    --dark-gray: #2C3E50;
    --error-red: #E74C3C;
    --success-green: #2ECC71;
    --transition-speed: 0.3s;
  }

body, p {
    /* margin: 0; */
    box-sizing: border-box;
}

.footer_container {
    background-color: var(--primary-color); 
    color: white;
    font-family: 'Arial', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    
}

.footer_content_container{
    display: flex;
    justify-content: space-evenly;
    min-height: 15vh;
    padding-top: 1%;
    width: 100%;
}

.footer_line_container{
    width: 98%;
}

.social_icon_container{
    /* width: 3vw; */
    display: flex;
    justify-content: center;
    gap: 30%;
    padding-top: 20%;
}
.social_icon{
    color: var(--thecolor);
    font-size: 3vw;
    cursor: pointer;
}
.social_icon:hover{
    color: white;
}

.footer_credits{
    /* display: flex; */
    margin-top: -1.5vh;
    margin-left: auto;
    padding-right: 1vw;
    font-size: x-small;
    text-align: right;
}
.footer_credits >p > a{
    color: var(--secondary-color);
    cursor: pointer;
}

.footer_credits >p > a:hover{
    color: rgb(196, 192, 192);
    font-weight: bold;
}
.data_outer_container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 25%;
}
.data_container{
    min-height: 115%;
    display: flex;
    align-items: center;
    flex-direction: column;
    
}


.vertical_line{
    border-right: 2px solid var(--secondary-color);
    height: 100%;
}

.nav_item {
    display: block;
    /* margin: 0 -1vw; */
    /* position: relative; */
    transition: color 0.3s ease;
    cursor: pointer;
    color: var(--secondary-color);
    font-size: 1vw;
    font-weight: bold;
    /* text-align: center; */
    min-width: 9vw;
}

.nav_group{
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    justify-content: center;
    padding-top: 8%;
    /* width: 20vw; */
    /* text-align: left; */
}
.nav_item:hover {
    color: white;
}
.active{
    /* margin: 0 -1vw; */
    /* position: relative; */
    transition: color 0.3s ease;
    cursor: pointer;
    text-decoration: underline;
    color: var(--tiertiary-color);
    font-size: 1vw;
    /* text-align: center; */
}

.nav_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50%;
    /* margin-top: -5%; */
}
.data_container{
    font-size: 1vw;
}

.get_started{
    /* font-size: 1.3vw; */
    margin-left: -20%;
    margin-bottom: -.01%;
}

.store_hours_row{
    display: flex;
    justify-content: space-between;
    padding-top: 0.3vh;
    width: 100%;
    font-weight: bolder;
    font-size: .86vw;
}
.store_hours_day{
    width: 8vw;
}

.store_hours_time{
    width: 10vw;
}

.contact_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1vh;
}

.email_button{
    background-color: transparent;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 87%;
    font-size: .9vw;
    font-weight: bold;
    cursor: pointer;
}
.email_button:hover{
    color: var(--secondary-color);
}

.map_button{
    background-color: transparent;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 105%;
    font-weight: bold;
    font-size: .9vw;
    cursor: pointer;
}

.map_button:hover{
    color: var(--secondary-color);
}

.phone_tag{
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 51%;
    font-size: .9vw;
    font-weight: bold;
    cursor: pointer;
    margin-left: .5vw;
}

.phone_tag:hover{
    color: var(--secondary-color);
}

.whats_app_tag{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 55%;
    font-size: .9vw;
    font-weight: bold;
    cursor: pointer;
}

.whats_app_tag:hover{
    color: var(--secondary-color);
}

