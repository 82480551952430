:root {
  --primary-color: #16181D;
  --secondary-color: rgba(214, 163, 11, 0.816);
  --tiertiary-color: rgba(162, 123, 5, 0.936);
  --light-gray: #ECF0F1;
  --dark-gray: #2C3E50;
  --error-red: #E74C3C;
  --success-green: #2ECC71;
  --transition-speed: 0.3s;
}

.data_wrapper {
  position: relative;
  top: -30vh;
  min-height: 100vh;
  background-color: var(--primary-color);
  color: white;
  font-size: 1vw;
  padding-left: 3vw;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.heading {
  color: var(--secondary-color);
  margin-top: 1vh;
}

.date {
  font-size: small;
  margin-bottom: -1vh;
  color: #666;
}

.text {
  padding-bottom: 1vh;
}

.nav_img {
  display: flex;
  justify-content: center;
  position: relative;
  height: 40vh;
  overflow: hidden;
}


.wrapper {
  /* width: 100vw; */
  margin: 0;
  min-height: 100vh;
  background-color: var(--primary-color);
}


.nav_img_container {
  position: relative;
  /* min-height: 100vh; */
  font-family: 'Saira Semi Condensed', sans-serif;

}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(161, 125, 17, 0.3);
}

.txt_overlay_container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 39.5vh;
  padding-left: 1vw;
  color: white;
  font-size: 1.5vw;
}

.title {
  margin: 0;
  position: absolute;
  /* left: 50%; */
  /* transform: translateX(-50%); */
}

.contact_container{
  margin-bottom: -22vh;
}