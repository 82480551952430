:root {
    --primary-color: #16181D;
    --secondary-color: rgba(214, 163, 11, 0.816);
    --tiertiary-color: rgba(162, 123, 5, 0.936);
    --thecolor: rgb(179, 136, 9);
    --light-gray: #ECF0F1;
    --dark-gray: #2C3E50;
    --error-red: #E74C3C;
    --success-green: #2ECC71;
    --transition-speed: 0.3s;
  }
 
 
 .car_card {
     display: flex;
     flex-direction: column;
     align-items: center;
     background-color: var(--primary-color);
     border: 1.5px solid white;
     margin: 2vh;
     /* width: 15.5vw; */
     box-shadow: grey 4px 4px 20px;
     transition: transform 0.3s ease, box-shadow 0.3s ease;
 }

 .car_card:hover {
     transform: translateY(-5px);
     box-shadow: grey 0px 19px 33px;
     cursor: pointer;
 }

 .car_image_container {
     position: relative;
     height: 23vh;
     width: 30vh;
     overflow: hidden;
     margin-top: -1vh;
     z-index: 1;
 }

 .carImageCard {
     width: 100%;
     height: 100%;
     object-fit: cover;
 }

 .car_info {
     display: flex;
     flex-direction: column;
     width: 90%;
     color: white;
 }

 .car_miles_price_container {
     display: flex;
     justify-content: space-between;
     margin: .8vh 0;
 }

 .car_miles {
     font-size: .9vw;
     line-height: 3vh;
     color: hsl(0, 0%, 62%);
     margin-bottom: .5vh;
     font-weight: 600;
 }

 .car_price {
     font-size: 1vw;
     line-height: 2vh;
     color: var(--light-gray);
     font-weight: 600;
 }

 .car_title {
     color: white;
     margin-top: 1vh;
     font-size: 1.8vh;
     text-align: left;
     font-weight: 600;
 }

 .car_body_type {
     color: rgb(184, 184, 184);
     font-size: .9vw;
     margin-top: .8vw;
 }

 .location {
     font-weight: 400;
     font-style: normal;
     font-size: .85vw;
     color: var(--light-gray);
     font-weight: 600;
 }

 .car_info p {
     margin: .5vh 0;
     color: #7f7f7f;
     padding-bottom: 1vh;
 }

 .add-inventory {
     justify-content: center;
     align-items: center;
     padding: 1vh 1vh;
     transition: all 0.3s;
     border: var(--thecolor);
     background-color: var(--thecolor);
     color: rgb(255, 255, 255);
     border-radius: 4px;
     cursor: pointer;
     font-size: 1vw;
     font-weight: bold;
     margin-left: 4vw;
 }

 .contact_container {
     display: flex;
     justify-content: space-between;
 }

 .contact_container:hover {
     display: flex;
     justify-content: space-between;
     align-items: center;
     color: white;
 }

 /* hr{
    color: black;
    font-weight: bolder;
} */
 .phone-number {
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: transparent;
     border: none;
     text-decoration: underline;
     color: var(--thecolor);
     font-weight: bold;
     font-size: .85vw;
 }

 .phone-number:hover {
     color: lightgray;
 }

 .interested {
    background-color: var(--thecolor);
    color: white;
     font-weight: bold;
     font-size: .85vw;
     padding: .4vw .7vw;
     cursor: pointer;
     border: solid 2px var(--thecolor);
     transition: background-color 0.3s ease;
 }

 .interested:hover {
    border: solid 2px var(--thecolor);
     background-color: transparent;
     color: var(--thecolor);
     
 }

 .delete-btn {
     position: absolute;
     top: 5%;
     left: 40%;
     background-color: transparent;
     border: none;
     cursor: pointer;
     height: 6vh;
     z-index: 2;
 }

 .delete-btn:hover {
     background-color: transparent;
     border: none;
     cursor: pointer;
 }

 .trash-img {
     height: 5vh;
     z-index: 2;
 }

 .trash-img:hover {
     height: 6vh;
 }

 .sold-btn {
     position: absolute;
     top: 5%;
     left: 75%;
     background-color: transparent;
     border: none;
     cursor: pointer;
     height: 6vh;
     z-index: 2;
 }

 .sold-btn:hover {
     background-color: transparent;
     border: none;
     cursor: pointer;
 }

 .sold-img {
     height: 5.5vh;
     z-index: 2;
 }

 .sold-img:hover {
     height: 6.5vh;
 }

 .edit-img {
     height: 5vh;
     z-index: 2;
 }

 .edit-img:hover {
     height: 6vh;
 }

 .edit-btn {
     position: absolute;
     top: 5%;
     left: 5%;
     background-color: transparent;
     border: none;
     cursor: pointer;
     height: 6vh;
     z-index: 2;
 }



 .admin-btn-container {
     background-color: rgba(240, 240, 240, 0.388);
     position: absolute;
     top: 5%;
     left: 0%;
     display: flex;
     justify-content: space-between;
     align-items: center;
     width: 100%;
     height: 7.5vh;
     z-index: 2;
 }