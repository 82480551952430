:root {
    --primary-color: #16181D;
    --secondary-color: rgba(214, 163, 11, 0.816);
    --tiertiary-color: rgba(162, 123, 5, 0.936);
    --light-gray: #ECF0F1;
    --dark-gray: #16181D;
    --error-red: #E74C3C;
    --success-green: #2ECC71;
    --transition-speed: 0.3s;
}

.contact_card_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 35vw;
    padding: 2em;
    border: 1px solid whitesmoke;
    border-radius: 20px;
    background-color: #1B1F26;
}

.contact_card_container input,
.contact_card_container select,
.contact_card_container textarea,
.contact_card_container button {
    padding: .7vw 1vw;
    margin-bottom: 1vw;
    border: .5px solid var(--primary-color);
    border-color: none;
    border-radius: 6px;
    font-size: 1vw;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.contact_card_container input:focus,
.contact_card_container textarea:focus,
.contact_card_container select:focus {
    border: 1px solid var(--secondary-color);
    box-shadow: var(--secondary-color);
    outline: none;
}
.send_btn_container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.contact_card_container button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 4vh;
    font-size: 2.3vh;
    font-weight: 550;
    color: white;
    cursor: pointer;
    background-color: var(--secondary-color);
    transition: background-color 0.4s;
}

.contact_card_container button:hover {
    background-color: var(--tiertiary-color);
}

.data_clause {
    font-size: .7vw;
    color: white;
    padding: .5vw;
}

.privacyp_link {
    color: white;
    text-decoration: underline;
}

.privacyp_link:hover {
    color: white;
    font-weight: 600;
    text-decoration: underline;
}

.error_message {
    color: var(--error-red);
    margin-bottom: 18px;
    text-align: center;
    font-weight: 600;
}

.success_message {
    color: var(--secondary-color);
    margin-bottom: 18px;
    text-align: center;
    font-weight: 600;
}


.contact_card_container input::placeholder {
    color: #a9a9a9;
}


/* Google ReCAPTCHA Styles */
.recaptcha{
    background-color: var(--secondary-color);
}

/* .grecaptcha-badge { opacity:0;}  */


/* .anchor-light{
    background-color: rgb(8, 24, 241);
}

@media only screen and (max-width: 500px) {
    .g-recaptcha {
        transform:scale(0.77);
        transform-origin:0 0;
    }
} */