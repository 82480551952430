:root {
    --primary-color: #16181D;
    --secondary-color: rgba(214, 163, 11, 0.816);
    --tiertiary-color: rgba(162, 123, 5, 0.936);
    --thecolor: #b38809;
    --light-gray: #ECF0F1;
    --dark-gray: #2C3E50;
    --error-red: #E74C3C;
    --success-green: #2ECC71;
    --transition-speed: 0.3s;
}


.header_top_container {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    background-color: var(--primary-color);
    height: 6vh;
    padding: 0 .9vw;
}

.header_top_right{
    display: flex;
    align-items: center;
    gap: 1vw;
    color: white;
    width: 49%;
    justify-content: space-between;
    padding: .5vw;
}
.header_top_right_data_container{
    width: 75%;
    display: flex;
    gap: 1vw;
}
.top_right_address_container{
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
    text-decoration: none;
    color: white;
}
.header_top_right_address{
    border: none;
    background-color: transparent;
    color: inherit;
    /* width: 100%; */
    font-size: .8vw;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
}
.header_top_right_phone_container{
    text-decoration: none;
    font-size: .8vw;
    width: 28%;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: white;
    cursor: pointer;
}
.top_right_address_container:hover{
    color: var(--tiertiary-color)
}
.header_top_right_phone{
    text-decoration: none;
    /* width: 85%; */
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: inherit;
}
.header_top_right_phone_container:hover{
    color: var(--tiertiary-color)
}
.social_icon_container{
    /* width: 3vw; */
    gap: 1vw;
    display: flex;
    justify-content: space-between;
    padding: 0 1.5vw 0 0;
}
.social_icon{
    color: var(--thecolor);
    font-size: 1.5vw;
    cursor: pointer;
}
.social_icon:hover{
    color: white;
}

.header_logo {
    height: 6vh;
    width: 8vw;
    transition: opacity 0.3s ease;
}

.header_logo:hover {
    background-color: var(--thecolor);
    animation: glowing 2s infinite;
}

.header_bottom_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--light-gray);
    height: 5vh;
}
.nav_bottom_right_container{
    display: flex;
    padding: 1vw;
}

.nav_item {
    margin: 0 1vw;
    position: relative;
    transition: color 0.3s ease;
    cursor: pointer;
    color: var(--primary-color);
    font-size: .9vw;
    font-weight: 600;
    text-align: center;
}
.active{
    margin: 0 1vw;
    position: relative;
    transition: color 0.3s ease;
    cursor: pointer;
    color: var(--tiertiary-color);
    font-size: .9vw;
    font-weight: 900;
    text-align: center;
}

.login_img {
    height: 3vh;
}

.nav_item_btn {
    transition: color 0.3s ease;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 20x;
    text-align: center;
    font-weight: 550;
    display: flex;
    align-items: center;
    gap: .2vw;
    background-color: transparent;
    border: none;
    font-size: .9vw;
}

.modalOpen{
    transition: color 0.3s ease;
    cursor: pointer;
    color: var(--tiertiary-color);
    font-size: 20x;
    text-align: center;
    font-weight: 800;
    display: flex;
    align-items: center;
    gap: .2vw;
    background-color: transparent;
    border: none;
    font-size: .9vw;
}

.nav_item_btn:hover {
    color: var(--tiertiary-color);
}

.nav_item:hover {
    color: var(--tiertiary-color);
}

.header_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;


}

.header_login:hover {
    background-color: #e67d2248;
}

@keyframes glowing {
    0% {
      box-shadow: 0 0 5px rgba(214, 163, 11, 0.816);
    }
    50% {
      box-shadow: 0 0 20px rgba(214, 163, 11, 0.816);
    }
    100% {
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
    }
  }

  .glow {
  
  }


